<template>
	<div>
		<v-card>

			<v-data-table :headers="headers" :options.sync="options" :items="lista" :items-per-page="itemsPerPage"
				:loading="loadingTable" hide-default-footer :key="`list-sales-invoices-${tenantId}`" class="elevation-1">
				<template v-slot:top>
					<ExpandableFilters classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
						iconButtom="mdi-upload" classTitle="col-0" :filters=filters @datafilters=cargarLista(true) />
					<!-- <v-col>
						<v-btn  color="primary" small  class="ma-2 white--text" 
							@click="exportar()"
						>
						<v-icon left >mdi-microsoft-excel</v-icon>
						Exportar
					</v-btn>
					</v-col> -->
				</template>
				<template v-slot:item.opcion="{ item }">
					<v-btn icon color='red' target="_blank" :to="`/retencion-emitida/${item.payment_id}`">
						<v-icon color='#ff9800'>mdi-pencil</v-icon>
					</v-btn>

				</template>

				<template v-slot:footer>
					<v-pagination class='mt-10' v-model="currentPage" :length="pageCount" @input="handlePageChange"
						total-visible=10></v-pagination>
				</template>

			</v-data-table>
		</v-card>
	</div>
</template>


<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from '../general/ExpandableFilters'
import AccountingEntries from '../general/AccountingEntries'
import Vue from 'vue'

export default {
	name: "RetencionesEmitidasComponent",
	components: {
		ExpandableFilters,
		AccountingEntries
	},
	data: () => ({
		headers: [
			{ text: "Opciones", value: "opcion" },
			{ text: "Numero", value: "ret_numero" },
			{ text: "Fecha", value: "effective_date" },
			{ text: "Estado", value: "estado" },
			{ text: "Proveedor", value: "proveedor" },
			{ text: "Comprobante de venta", value: "invoice_number" },


		],
		lista: [],
		options: {},
		dialog: false,
		notifications: false,
		sound: true,
		widgets: true,
		panel: '',


		itemsEstado: [
			{ estadoId: "PMNT_SENT", estadoNombre: "Listo" },
			{ estadoId: "PMNT_NOT_PAID", estadoNombre: "En proceso" },
			{ estadoId: "PMNT_VOID", estadoNombre: "Canceladas" }
		],
		pagos: [],
		asientos: [],

		filters: [
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Número',
				type: 'input_text'
			},
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-3 pl-0 pl-sm-2',
				v_model: '',
				label: 'Proveedor',
				type: 'input_text'
			},
			{
				cols: 12,
				class: 'py-0 py-md-2 col-md-3 pr-0 pl-0 pr-sm-2 mb-1',
				v_model: [],
				label: 'Fecha',
				type: 'input_date_time',
				clearable: true,
			},

			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Estado',
				type: 'input_select',
				text: 'name',
				value: 'status_id',
				clearable: true,
				items: [
					{ 'status_id': 'PMNT_SENT', 'name': 'Listo' },
					{ 'status_id': 'PMNT_NOT_PAID', 'name': 'En proceso' },
					{ 'status_id': "PMNT_VOID", 'name': "Canceladas" }
				],
			},
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Establecimiento',
				type: 'input_select',
				text: 'store_name',
				value: 'product_store_id',
				clearable: true,
				items: [],
			},
		],
		currentPage: 1,
		pageCount: 1,
		itemsPerPage: 20,
		searchNumber: "",
		searchFechaInv: "",
		searchEstado: "",
		searchCliente: "",
		required: [
			v => !!v || 'El campo es requerido'
		]
	}),
	watch: {
		options: {
			handler() {
				this.cargarLista(false)
			},
			deep: true
		}
	},
	computed: {
		...mapState("master", ["loadingTable", "user", "tenantId", "paramAlertQuestion"]),

		...mapGetters("access", ["btnAbrirCaja"]),

		updateHeader() {
			//return (this.cabecera.invoice_type_id =='PAYROL_INVOICE' || (this.cabecera.invoice_type_id =='INVOICE_HONORARIOS' && this.cabecera.status_id =='INVOICE_READY'))
		},


	},
	methods: {

		...mapMutations("master", ["setUrl", "setOverlay", "setMenu", "setLoadingTable", "setTitleToolbar"]),

		...mapActions("master", ["requestApi", "alertNotification"]),

		cargarLista(filtrar) {

			if (filtrar) this.currentPage = 1;
			this.lista = []
			this.setLoadingTable(true);
			this.setUrl("retencion-emitida");
			this.requestApi({
				method: "GET",
				data: {
					invoiceType: this.filters[3].v_model,
					page: this.currentPage,
					page_count: this.pageCount,
					page_size: this.itemsPerPage,
					proveedor: this.filters[1].v_model,
					retencion_numero: this.filters[0].v_model,
					status_id: this.filters[3].v_model,
					effective_date: this.filters[2].v_model,
					//product_store_id: this.filters[4].v_model
				},
			}).then(res => {
				//console.log(res);
				this.lista = res.data._embedded.retencion_emitida; //invoice[0].invoice;				
				this.pageCount = res.data.page_count; //invoice[0].page_count;
				this.setLoadingTable(false);
			});
		},

		formInvoice(invid) {
			this.setUrl("invoice/" + invid);
			this.requestApi({
				method: "GET",
				data: {},
			}).then(res => {

				this.asientos = res.data.asientos.sort((a, b) => { return a.acctg_trans_entry_seq_id - b.acctg_trans_entry_seq_id })
				this.cabecera = res.data.cabecera;
				this.itemsFactura = res.data.itemsFactura;
				this.totalesFactura = res.data.totales;
				this.pagos = res.data.pagos
				this.numeroFactura.numero = res.data.cabecera.invoice_number
				this.numeroFactura.ptoEmision = res.data.cabecera.codigo_punto_emision
				this.numeroFactura.establecimiento = res.data.cabecera.codigo_establecimiento
				this.numeroFactura.claveAcceso = res.data.cabecera.autorizacion_sri
				this.dialog = true

			});
		},




		handlePageChange(value) {
			this.currentPage = value
			this.cargarLista(false);
		},
		getEstablecimiento() {

			this.setUrl('establecimiento')
			this.requestApi({
				method: 'GET',
				data: {}
			}).then(res => {

				this.filters[4].items = res.data._embedded.establecimiento

			}).then(() => {

			})

		},


	},
	mounted() {
		this.setTitleToolbar('RETENCIONES EMITIDAS')
		//this.cargarLista(false);

	},
}
</script>